@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.svg-icon {
  display: block;
  flex-shrink: 0;
}
.svg-icon.ib {
  display: inline-block;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

.hidden,
[hidden] {
  display: none !important;
}

/* Inter-Regular в _layout.njk */
@font-face {
  font-weight: 500;
  font-family: "inter";
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/Inter-Medium.woff2") format("woff2");
}
@font-face {
  font-weight: bold;
  font-family: "inter";
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/Inter-Bold.woff2") format("woff2");
}
@font-face {
  font-weight: 800;
  font-family: "inter";
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/Inter-ExtraBold.woff2") format("woff2");
}
@keyframes slideIn {
  to {
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes zoomIn {
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes serverSideBlinking {
  to {
    opacity: 0.5;
  }
}
.btn-g {
  position: relative;
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 19.5px 16px;
  font-weight: 500;
  font-size: 16px;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  transition: 0.3s;
  user-select: none;
}
@media (hover: hover), (pointer: fine) {
  .btn-g:hover {
    opacity: 0.8;
  }
}
.btn-g:not(:last-child) {
  margin-bottom: 16px;
}
.btn-g:disabled, .btn-g.disabled {
  cursor: default;
  filter: grayscale(1) contrast(0.6);
  pointer-events: none;
}

.btn-g_white {
  background: #ffffff;
}
@media (hover: hover), (pointer: fine) {
  .btn-g_white:hover {
    background: #d6d6d6;
  }
}
.btn-g_white:active {
  background: #bbbbbb;
}

.btn-g_blue {
  color: #ffffff;
  background: #2850e1;
}
@media (hover: hover), (pointer: fine) {
  .btn-g_blue:hover {
    background: #133fe0;
  }
}
.btn-g_blue:active {
  background: #0f2fa7;
}

.btn-g_red {
  color: #ffffff;
  background: #eb5757;
}
@media (hover: hover), (pointer: fine) {
  .btn-g_red:hover {
    background: #ec4e4e;
  }
}
.btn-g_red:active {
  background: #c63232;
}

.btn-g_black-red {
  color: #eb5757;
  background: #131419;
}
@media (hover: hover), (pointer: fine) {
  .btn-g_black-red:hover {
    background: #151517;
  }
}
.btn-g_black-red:active {
  background: #000000;
}

.btn-g_white-transparent {
  color: #ffffff;
  background: none;
  border: 1px solid #ffffff;
}
@media (hover: hover), (pointer: fine) {
  .btn-g_white-transparent:hover {
    color: #000000;
    background: #ffffff;
  }
  .btn-g_white-transparent:hover div {
    color: #000000;
  }
}

.btn-g__icon {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.btn-g__icon + .btn-g__text {
  margin-left: 15px;
}

.btn-g app-loader {
  --size: 20px;
}

.btn_square {
  border-radius: 10px;
}

.native-select-g {
  --color: #333333;
  padding: 0.3em;
  padding-right: calc(0.3em + 13px);
  color: var(--color);
  font-family: inherit;
  background: none;
  background-image: linear-gradient(45deg, transparent 50%, var(--color) 50%, var(--color) 66%, transparent 66%), linear-gradient(135deg, transparent 30%, var(--color) 8%, var(--color) 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) 50%, calc(100% - 4px) 50%;
  background-size: 4px 4px, 4px 4px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  appearance: none;
  appearance: none;
  appearance: none;
}

.native-select-g_no-arrow {
  padding-right: 0.3em;
  background-image: none;
  background-repeat: no-repeat;
  background-position: initial;
  background-size: auto;
}

.native-select-g option {
  color: initial;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --100vh: 100vh;
}

html,
body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "inter", sans-serif;
}

.btn-g:focus:not(.focus-visible),
.native-select-g:focus:not(.focus-visible),
button:focus:not(.focus-visible),
input[type=checkbox]:focus:not(.focus-visible) {
  outline: 0;
}