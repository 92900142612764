/* Inter-Regular в _layout.njk */

@font-face {
  font-weight: 500;
  font-family: "inter";
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/Inter-Medium.woff2") format("woff2");
}

@font-face {
  font-weight: bold;
  font-family: "inter";
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/Inter-Bold.woff2") format("woff2");
}

@font-face {
  font-weight: 800;
  font-family: "inter";
  font-style: normal;
  font-display: swap;
  src: url("../../assets/fonts/Inter-ExtraBold.woff2") format("woff2");
}

