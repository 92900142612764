.svg-icon {
	display: block;
	flex-shrink: 0;

  &.ib {
    display: inline-block;
  }
}

.visually-hidden {
	position: absolute !important;

	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	overflow: hidden !important;

	border: 0 !important;

	clip: rect(1px, 1px, 1px, 1px) !important;
}

.hidden,
[hidden] {
  display: none !important;
}
