@import "base/generic.reset.scss";
@import "base/utilities.scss";
@import "base/settings.fonts";
@import "animations";
@import "components/btn-g.scss";
@import "components/native-select-g.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --100vh: 100vh;
}

html,
body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "inter", sans-serif;
}

.btn-g:focus:not(.focus-visible),
.native-select-g:focus:not(.focus-visible),
button:focus:not(.focus-visible),
input[type="checkbox"]:focus:not(.focus-visible) {
  outline: 0;
}
