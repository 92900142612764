.native-select-g {
  --color: #333333;

  padding: 0.3em;
  padding-right: calc(0.3em + 13px);

  color: var(--color);
  font-family: inherit;

  background: none;
  background-image:
    linear-gradient(45deg, transparent 50%, var(--color) 50%, var(--color) 66%, transparent 66%),
    linear-gradient(135deg, transparent 30%, var(--color) 8%, var(--color) 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) 50%, calc(100% - 4px) 50%;
  background-size: 4px 4px, 4px 4px;
  border: none;
  cursor: pointer;

  transition: 0.3s;

  appearance: none;
  appearance: none;
  appearance: none;
}

.native-select-g_no-arrow {
  padding-right: 0.3em;

  background-image: none;
  background-repeat: no-repeat;
  background-position: initial;
  background-size: auto;
}

.native-select-g option {
  color: initial;
}

