.btn-g {
  position: relative;

  display: block;
  width: 100%;
  max-width: 300px;
  padding: 19.5px 16px;

  font-weight: 500;
  font-size: 16px;
  font-family: inherit;
  text-align: center;
  text-decoration: none;

  border: none;
  border-radius: 32px;
  cursor: pointer;


  transition: 0.3s;
  user-select: none;

  &:hover {
    @media (hover: hover), (pointer: fine) {
      opacity: 0.8;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:disabled,
  &.disabled {
    cursor: default;

    filter: grayscale(1) contrast(0.6);
    pointer-events: none;
  }
}

.btn-g_white {
  background: #ffffff;

  &:hover {
    @media (hover: hover), (pointer: fine) {
      background: #d6d6d6;
    }
  }

  &:active {
    background: #bbbbbb;
  }
}

.btn-g_blue {
  color: #ffffff;

  background: #2850e1;

  &:hover {
    @media (hover: hover), (pointer: fine) {
      background: #133fe0;
    }
  }

  &:active {
    background: #0f2fa7;
  }
}

.btn-g_red {
  color: #ffffff;

  background: #eb5757;

  &:hover {
    @media (hover: hover), (pointer: fine) {
      background: #ec4e4e;
    }
  }

  &:active {
    background: #c63232;
  }
}

.btn-g_black-red {
  color: #eb5757;

  background: #131419;

  &:hover {
    @media (hover: hover), (pointer: fine) {
      background: #151517;
    }
  }

  &:active {
    background: #000000;
  }
}

.btn-g_white-transparent {
  color: #ffffff;

  background: none;
  border: 1px solid #ffffff;

  &:hover {
    @media (hover: hover), (pointer: fine) {
      color: #000000;

      div {
        color: #000000;
      }

      background: #ffffff;
    }
  }
}

.btn-g__icon {
  position: absolute;
  top: 50%;
  left: 16px;

  transform: translateY(-50%);
}

.btn-g__icon + .btn-g__text {
  margin-left: 15px;
}

.btn-g app-loader {
  --size: 20px;
}

.btn_square {
  border-radius: 10px;
}
