@keyframes slideIn {
  to {
    transform: translate(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
 opacity: 0;
 }

  to {
 opacity: 1;
 }
}

@keyframes zoomIn {
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes serverSideBlinking {
  to {
    opacity: 0.5;
  }
}
